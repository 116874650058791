<template>
  <div class="block_section_title">
    <slot />
    <img src="../../assets/line.png" alt="">
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.block_section_title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  font-weight: bold;

  img {
    width: 100%;
    margin-top: 7px;
  }
}
</style>
